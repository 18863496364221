var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home"},[_c('Shuffl'),_c('Tabbar',{staticClass:"head-tab"}),_c('div',{staticClass:"content"},[_vm._m(0),_c('form',{staticClass:"form"},[_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_c('div',{staticClass:"from-foot"},[_c('div',{staticClass:"from-foot-left"},[_c('label',{attrs:{"for":"agress"}},[_c('img',{staticClass:"agreess-img",attrs:{"src":_vm.cbox
                  ? require('@/assets/image/index/check.png')
                  : require('@/assets/image/index/uncheck.png'),"alt":""}})]),_c('input',{attrs:{"type":"checkbox","id":"agress"},on:{"click":_vm.agree}}),_c('div',{staticClass:"agree"},[_vm._v("我已仔细阅读并接受")]),_c('router-link',{attrs:{"to":"/Useragr"}},[_vm._v("注册许可协议")])],1),_c('div',{staticClass:"from-foot-right"},[_c('span',[_vm._v("已有账号？")]),_c('router-link',{attrs:{"to":"/Login"}},[_vm._v("立即登录")])],1)]),_c('button',{staticClass:"btn-submit"},[_vm._v("立即注册")])])]),_c('Footer',{staticClass:"foot-tab"})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tit"},[_c('span',[_vm._v("注册账号")]),_c('span',[_vm._v("Registered account")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"out-form"},[_c('label',{attrs:{"for":"name"}},[_vm._v("用户名")]),_c('input',{attrs:{"type":"text","id":"name","placeholder":"请输入您的用户名"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"out-form"},[_c('label',{attrs:{"for":"pwa"}},[_vm._v("密码")]),_c('input',{attrs:{"type":"password","id":"pwa","placeholder":"请输入您的密码"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"out-form"},[_c('label',{attrs:{"for":"pwa2"}},[_vm._v("确认密码")]),_c('input',{attrs:{"type":"password","id":"pwa2","placeholder":"请再次输入您的密码"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"out-form"},[_c('label',{attrs:{"for":"name2"}},[_vm._v("真实姓名")]),_c('input',{attrs:{"type":"text","id":"name2","placeholder":"请输入您的真实姓名"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"out-form"},[_c('label',{attrs:{"for":"card"}},[_vm._v("身份证号")]),_c('input',{attrs:{"type":"password","id":"card","placeholder":"请输入您的身份证号"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"out-form"},[_c('label',{attrs:{"for":"tel"}},[_vm._v("手机号")]),_c('input',{attrs:{"type":"password","id":"tel","placeholder":"请输入您的手机号"}})])
}]

export { render, staticRenderFns }