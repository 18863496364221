<template>
  <div class="tabbar">
    <img class="tabbar-img" src="../assets/image/index/logo.png" alt="" />

    <div class="out-box">
      <ul class="tabbar-ul">
        <li
          :class="{ act: activeIndex == index }"
          v-for="(item, index) in listTar"
          :key="index"
          @click="changePage(index)"
        >
          {{ item }}
        </li>
      </ul>
      <div class="btn">
        <router-link to="/Regist" class="regist">注册</router-link>|
        <router-link to="/Login" v-if="Login" class="login">登录</router-link>
        <span class="handelrevmo" @click="handelrevmo" v-else>退出登录</span>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "Tabbar",
    data() {
      return {
        listTar: ["网站首页", "联系我们", "我的发布"],
        Login: true,
      }
    },
    props: {
      activeIndex: {
        type: Number,
        default: 0,
      },
    },
    mounted() {
      const isLogin = sessionStorage.isLogin
      if (isLogin) {
        this.Login = false
      } else {
        this.Login = true
      }
    },
    methods: {
      handelrevmo() {
        sessionStorage.removeItem("isLogin")
        const isLogin = sessionStorage.isLogin
        if (isLogin) {
          this.Login = false
        } else {
          this.Login = true
        }
      },
      changePage(i) {
        console.log(this.activeIndex)
        this.activeIndex = i
        console.log(i, this.activeIndex)
        if (this.activeIndex == 0) {
          this.$router.push({ name: "face" })
        } else if (this.activeIndex == 1) {
          this.$router.push({ name: "Myself" })
        } else if (this.activeIndex == 2) {
          this.$router.push({ name: "Publish" })
        }
      },
    },
  }
</script>
<style lang="scss" scoped>
  .tabbar {
    display: flex;
    justify-content: space-evenly;
    box-sizing: border-box;
    height: 90px !important;
    padding: 10px 0;
    box-sizing: border-box;
    background-color: #ffff;
    .tabbar-img {
      width: 300px;
      height: 60px;
      color: #4f53ff;
    }
    .out-box {
      display: flex;
      align-items: center;
      .tabbar-ul {
        display: flex;
        justify-content: space-evenly;
        list-style: none;
        color: #000;
        font-weight: 700;
        align-items: center;
        li {
          margin: 0 10px;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          cursor: pointer;
        }
        .act {
          color: #4f53ff;
        }
        li.act::after {
          content: "";
          position: absolute;
          bottom: 20px;
          width: 3%;
          height: 5px;
          background-color: #4f53ff;
        }
      }
      .btn {
        margin-left: 100px;
        display: flex;
        color: #000;
        font-size: 14px;
        align-items: center;

        .regist,
        .login {
          display: block;
          width: 45px;
          height: 30px;
          border: none;
          outline: none;
          cursor: pointer;
          font-size: 14px;
          text-decoration: none;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #000;
        }
        .regist {
          border-radius: 15px 0 0 15px;
        }
        .login {
          border-radius: 0 15px 15px 0;
        }
      }
    }
  }
  .handelrevmo {
    cursor: pointer;
    margin-left: 5px;
  }
</style>
