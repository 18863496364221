<template>
    <div class="Publish">
        <Shuffl  />
        <Tabbar :activeIndex ="activeIndex" class="head-tab" />
        <PublishList v-if="isshow" @isshow = 'show' />
        <Publishbtn v-else  @handelClickDetermine = 'handelClickDetermine' />
          
        <Footer></Footer>
    </div>
   

</template>

<script>
import Tabbar from "@/components/Tabbar";
import Footer from "@/components/Footer";
import Shuffl from "@/components/Shuffl";
import PublishList from "./publishList.vue";
import Publishbtn from "./publishbtn.vue"
export default {
  components: { Tabbar, Footer, Shuffl ,PublishList,Publishbtn},
    name:"Publish",
    data() {
        return {
           activeIndex:2,
           isshow:true
        }
    },
    methods: {
      show(show){
        console.log(show);
        this.isshow = show
      },
      handelClick(){
        this.isshow = false
      },
      handelClickDetermine(show){
        this.isshow = show 
      }
    },

   
}
</script>

<style  lang="scss" scoped>
  .head-tab {
    position: absolute;
    top: 0;
    z-index: 99;
    min-width: 100%;
    height: 60px;
   
  }

</style>