import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import '@/style/iconfont.css'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.prototype.axios = axios
import VueAxios from "vue-axios";
Vue.use(VueAxios, axios)
Vue.use(ElementUI)

Vue.directive('title', {

    inserted: function(el, binding) {

        document.title = el.dataset.title

    }

})
Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')