<template>
  <div class="popular">
    <Shuffl />
    <Tabbar class="head-tab" :activeIndex="activeIndex" />
    <div class="content">
      <div class="left">
        <h1>杭州面具网络科技有限公司</h1>
        <h4>公司地址：浙江省杭州市西湖区丰盛九玺天城2号楼4层C5室</h4>
        <h4>联系电话：19857016747</h4>
        <h4>上班时间：上午9:00~12:00 下午14:00~18:00（法定节假日除外）</h4>
        <h4>电子邮箱： 521462234@qq.com</h4>
      </div>
      <div class="right">
        <img class="right-img" src="../assets/image/index/wap.png" alt="" />
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
  import Tabbar from "@/components/Tabbar"
  import Footer from "@/components/Footer"
  import Shuffl from "@/components/Shuffl"
  export default {
    components: { Tabbar, Footer, Shuffl },
    name: "Myself",
    data() {
      return {
        activeIndex: 1,
      }
    },
  }
</script>
<style lang="scss" scoped>
  .popular {
    width: 100%;
    height: auto;
    box-sizing: border-box;
    .head-tab {
      position: absolute;
      top: 0;
      z-index: 99;
      width: 100%;
      height: 60px;
    }
    .content {
      margin: 40px auto 10px;
      display: flex;
      justify-content: center;
      width: 80%;
      height: 375px;
      background-color: #1f1f1f;
      padding: 2%;
      box-sizing: border-box;
      .left {
        width: 35%;
        // border: 1px solid red;
        h1 {
          font-size: 27px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #ffffff;
          position: relative;
        }
        h1:before {
          content: "";
          display: block;
          width: 15px;
          height: 15px;
          position: absolute;
          right: 0;
          border-right: 3px solid #fff;
          border-top: 3px solid #fff;
          top: -15px;
          // right: 40px;
        }
        h1:after {
          content: "";
          display: block;
          width: 30px;
          margin-top: 20px;
          position: absolute;
          border-top: 4px solid #fff;
        }
        h4 {
          font-size: 11px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #ffffff;
          line-height: 23px;
          margin-top: 20px;
        }
        h4:nth-child(2) {
          margin-top: 80px;
        }
      }
      .right {
        margin-left: 100px;
        width: 45%;
        .right-img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
</style>
