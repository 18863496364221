<template>
  <div class="home">
    <!-- 轮播图 -->
    <Shuffl />
    <Tabbar class="head-tab" />
    <div class="content">
      <div class="tit">
        <span>注册账号</span>
        <span>Registered account</span>
      </div>
      <form class="form">
        <div class="out-form">
          <label for="name">用户名</label>
          <input type="text" id="name" placeholder="请输入您的用户名" />
        </div>
        <div class="out-form">
          <label for="pwa">密码</label>
          <input type="password" id="pwa" placeholder="请输入您的密码" />
        </div>
        <div class="out-form">
          <label for="pwa2">确认密码</label>
          <input type="password" id="pwa2" placeholder="请再次输入您的密码" />
        </div>
        <div class="out-form">
          <label for="name2">真实姓名</label>
          <input type="text" id="name2" placeholder="请输入您的真实姓名" />
        </div>
        <div class="out-form">
          <label for="card">身份证号</label>
          <input type="password" id="card" placeholder="请输入您的身份证号" />
        </div>
        <div class="out-form">
          <label for="tel">手机号</label>
          <input type="password" id="tel" placeholder="请输入您的手机号" />
        </div>
        <div class="from-foot">
          <div class="from-foot-left">
            <label for="agress"
              ><img
                class="agreess-img"
                :src="
                  cbox
                    ? require('@/assets/image/index/check.png')
                    : require('@/assets/image/index/uncheck.png')
                "
                alt=""
            /></label>
            <input type="checkbox" id="agress" @click="agree" />
            <div class="agree">我已仔细阅读并接受</div>
            <router-link to="/Useragr">注册许可协议</router-link>
          </div>
          <div class="from-foot-right">
            <span>已有账号？</span>
            <router-link to="/Login">立即登录</router-link>
          </div>
        </div>
        <button class="btn-submit">立即注册</button>
      </form>
    </div>
    <Footer class="foot-tab"></Footer>
  </div>
</template>

<script>
  import Tabbar from "@/components/Tabbar"
  import Footer from "@/components/Footer"
  import Shuffl from "@/components/Shuffl"
  export default {
    components: { Tabbar, Footer, Shuffl },
    name: "Regist",
    data() {
      return {
        cbox: false,
      }
    },
    methods: {
      agree() {
        this.cbox = !this.cbox
      },
    },
  }
</script>
<style lang="scss" scoped>
  .home {
    width: 100%;
    height: auto;
    box-sizing: border-box;
    .head-tab {
      position: absolute;
      top: 0;
      z-index: 99;
      width: 100%;
      height: 60px;
    }
    .content {
      margin-top: 53px;
      .tit {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        span:first-child {
          font-size: 30px;
          color: #333333;
        }
        span:nth-child(2) {
          font-size: 21px;
          font-family: Adobe Heiti Std;
          color: #ff5050;
        }
      }
      .form {
        color: #666666;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 43px;
        .out-form {
          width: 35%;
          border: 1px solid #adadad;
          margin-top: 30px;
          height: 45px;
          display: flex;
          align-items: center;
          border-radius: 5px;
          label {
            text-align: center;
            width: 128px;
            border-right: 1px solid #adadad;
          }
          input {
            flex: 1;
            border: none;
            text-align: center;
            outline: none;
          }
        }
        .from-foot {
          display: flex;
          width: 35%;
          margin-top: 47px;
          justify-content: space-between;
          a {
            color: #17a1ff;
          }
          .from-foot-left {
            display: flex;
            .agreess-img {
              cursor: pointer;
            }
            .agree {
              margin: 0 5px;
            }
            #agress {
              display: none;
            }
          }
        }
        .btn-submit {
          margin-top: 34px;
          color: #fff;
          background: #17a1ff;
          height: 54px;
          border: none;
          width: 20%;
          border-radius: 27px;
          font-size: 18px;
          font-family: Source Han Sans CN;
          text-indent: 2px;
          letter-spacing: 2px;
          cursor: pointer;
        }
      }
    }
    .foot-tab {
      margin-top: 320px;
    }
  }
</style>
