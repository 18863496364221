<template>
  <div class="face">
    <Shuffl />
    <Tabbar class="head-tab" :activeIndex="activeIndex" />

    <div class="content">
      <div class="txt">经营范围</div>
      <div class="content-list">
        <div class="content-list-feix" id="#anchor-0">
          <div class="content-list-yl-title">娱乐</div>
          <div class="content-list-yl-list">
            <div
              class="content-list-yl-item"
              v-for="(item, index) in dynamicArr1"
            >
              <router-link :to="{ path: '/Item', query: { id: item.id } }">
                <img :src="item.img" class="content-list-yl-img" alt="" />
              </router-link>
              <div class="content-list-yl-name">
                {{ item.tit }}
              </div>
              <div class="content-list-yl-Price">
                {{ item.Price }}
                <span> 万元</span>
              </div>
              <div class="content-list-yl-text" v-html="item.itemGs"></div>
              <router-link to="/consultation">
                <div class="content-list-yl-btn">咨询</div>
              </router-link>
            </div>
          </div>
        </div>
        <div class="content-list-feix" id="#anchor-1">
          <div class="content-list-yl-title">购物</div>
          <div class="content-list-yl-list">
            <div
              class="content-list-yl-item"
              v-for="(item, index) in dynamicArr2"
            >
              <router-link :to="{ path: '/Item', query: { id: item.id } }">
                <img :src="item.img" class="content-list-yl-img" alt="" />
              </router-link>
              <div class="content-list-yl-name">
                {{ item.tit }}
              </div>
              <div class="content-list-yl-Price">
                {{ item.Price }}
                <span> 万元</span>
              </div>
              <div class="content-list-yl-text" v-html="item.itemGs"></div>
              <router-link to="/consultation">
                <div class="content-list-yl-btn">咨询</div>
              </router-link>
            </div>
          </div>
        </div>
        <div class="content-list-feix" id="#anchor-2">
          <div class="content-list-yl-title">生活</div>
          <div class="content-list-yl-list">
            <div
              class="content-list-yl-item"
              v-for="(item, index) in dynamicArr3"
            >
              <router-link :to="{ path: '/Item', query: { id: item.id } }">
                <img :src="item.img" class="content-list-yl-img" alt="" />
              </router-link>
              <div class="content-list-yl-name">
                {{ item.tit }}
              </div>
              <div class="content-list-yl-Price">
                {{ item.Price }}
                <span> 万元</span>
              </div>
              <div class="content-list-yl-text" v-html="item.itemGs"></div>
              <router-link to="/consultation">
                <div class="content-list-yl-btn">咨询</div>
              </router-link>
            </div>
          </div>
        </div>
        <div class="content-list-feix" id="#anchor-3">
          <div class="content-list-yl-title">其他</div>
          <div class="content-list-yl-list">
            <div
              class="content-list-yl-item"
              v-for="(item, index) in dynamicArr4"
            >
              <router-link :to="{ path: '/Item', query: { id: item.id } }">
                <img :src="item.img" class="content-list-yl-img" alt="" />
              </router-link>
              <div class="content-list-yl-name">
                {{ item.tit }}
              </div>
              <div class="content-list-yl-Price">
                {{ item.Price }}
                <span> 万元</span>
              </div>
              <div class="content-list-yl-text" v-html="item.itemGs"></div>
              <router-link :to="{ path: '/Item', query: { id: item.id } }">
                <div class="content-list-yl-btn">咨询</div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <ul class="MaoDian">
        <li>
          <a
            href="javascript:void(0)"
            v-for="(item, index) in dynamicText"
            :class="{
              'MaoDian-item': true,
              'MaoDian-active': active === index,
            }"
            @click="goAnchor('#anchor-' + index, index)"
          >
            {{ item }}
          </a>
        </li>
      </ul>
    </div>
    <div class="face-fwli">
      <img src="@/assets/image/index/fwmk.png" alt="" />
    </div>
    <div class="about">
      <div class="about-center">
        <div class="about-center-title">关于我们</div>
        <p>
          面具网络科技有限公司成立于2023年，总部位于浙江省杭州市。我们是一家专注于信息发布和中介业务的公司，为客户提供全方位的服务。
        </p>
        <p>
          我们的核心业务是为客户提供专业高效的信息发布平台。客户可以通过我们的平台发布各种服务，目前主要包括各种类型软件的定制、设计、开发与运维。我们致力于为客户提供高效、优质、个性化的服务，帮助客户实现其目标。
        </p>
        <p>
          我们的团队由一群热爱互联网和信息服务的专业人士组成，具有丰富的行业经验和创新的思维方式。我们不断探索和创新，以不断满足客户需求，提供最佳的服务体验。
        </p>
        <p>
          我们坚信，只有客户满意才能真正获得成功。因此，我们始终以客户为中心，以诚信、专业和贴心的服务为宗旨，为客户提供卓越的服务体验。
        </p>
        <p>
          如果您需要信息发布或中介服务，欢迎联系我们。我们期待着与您合作，共同成长，共同发展。
        </p>
      </div>
    </div>
    <div class="showP">
      <!-- <div class="showpTit">
        <span>合作伙伴</span>
      </div>
      <div class="content-show">
        <div class="medium" v-for="(item, index) in hezuo" :key="index">
          <img :src="item.aimg" alt="" />
        </div>
      </div> -->
    </div>

    <Footer class="Footer" />
  </div>
</template>
<script>
  import Tabbar from "@/components/Tabbar"
  import Footer from "@/components/Footer"
  import Shuffl from "@/components/Shuffl"
  import { log } from "console"
  import { ref } from "vue"
  export default {
    components: { Tabbar, Footer, Shuffl },
    name: "Face",

    data() {
      return {
        active: 0,
        activeIndex: 0,
        dynamicText: ["娱乐", "购物", "生活", "其他"],
        dynamicArr1: [
          {
            id: 6,
            img: require("@/assets/image/index/content/news6.png"),
            tit: "旅游服务",
            Price: "2.8",
            itemGs:
              "<div>由合肥畅聊网络科技有限公司提供，联系电话：15867179796</div>",
          },
          {
            id: 3,
            img: require("@/assets/image/index/content/news3.png"),
            tit: "影视服务",
            Price: "3.1",
            itemGs:
              "<div>由杭州无虑网络科技有限公司提供，联系电话：13607030450</div>",
          },
          {
            id: 9,
            img: require("@/assets/image/index/content/news9.png"),
            tit: "社交服务",
            Price: "8.9",
            itemGs:
              "<div>由杭州无悠网络科技有限公司提供，联系电话：18768189561</div>",
          },
        ],
        dynamicArr2: [
          {
            id: 8,
            img: require("@/assets/image/index/content/news8.png"),
            tit: "餐饮美食",
            Price: "5.1",
            itemGs:
              "<div>由杭州无虑网络科技有限公司提供，联系电话：13607030450</div>",
          },
          {
            id: 2,
            tit: "男装会展",
            Price: "4.9",
            itemGs:
              "<div>由杭州无悠网络科技有限公司提供，联系电话：18768189561</div>",
            img: require("@/assets/image/index/content/news2.png"),
          },
          {
            id: 10,
            tit: "化妆品牌",
            Price: "7.1",
            itemGs:
              "<div>由杭州无悠网络科技有限公司提供，联系电话：18768189561</div>",
            img: require("@/assets/image/index/content/news10.png"),
          },
        ],
        dynamicArr3: [
          {
            id: 1,
            img: require("@/assets/image/index/content/news1.png"),
            tit: "母婴服务",
            Price: "4.7",
            itemGs:
              "<div>由合肥畅聊网络科技有限公司提供，联系电话：15867179796</div>",
          },
          {
            id: 7,
            img: require("@/assets/image/index/content/news7.png"),
            tit: "生活家具",
            itemGs:
              "<div>由杭州无悠网络科技有限公司提供，联系电话：18768189561</div>",
            Price: "5.8",
          },
          {
            id: 11,
            img: require("@/assets/image/index/content/news11.png"),
            tit: "音乐服务",
            Price: "6.5",
            itemGs:
              "<div>由合肥畅聊网络科技有限公司提供，联系电话：15867179796</div>",
          },
        ],
        dynamicArr4: [
          {
            id: 4,
            img: require("@/assets/image/index/content/news4.png"),
            tit: "环保",
            Price: "3.1",
            itemGs:
              "<div>由杭州无虑网络科技有限公司提供，联系电话：13607030450</div>",
          },
          {
            id: 5,
            img: require("@/assets/image/index/content/news5.png"),
            tit: "通信设备",
            Price: "4.0",
            itemGs:
              "<div>由合肥畅聊网络科技有限公司提供，联系电话：15867179796</div>",
          },
          {
            id: 12,
            img: require("@/assets/image/index/content/news12.png"),
            tit: "宠物服务",
            Price: "5.8",
            itemGs:
              "<div>由合肥畅聊网络科技有限公司提供，联系电话：15867179796</div>",
          },
        ],
        hezuo: [
          { aimg: require("@/assets/image/index/content/riteng.png") },
          { aimg: require("@/assets/image/index/content/ouyue.png") },
          { aimg: require("@/assets/image/index/content/aikui.png") },
        ],
        newData: [],
        afterData: [],
      }
    },

    methods: {
      goAnchor(selector, index) {
        this.active = index
        var anchor = document.getElementById(selector)
        anchor.scrollIntoView()
      },
      onScroll() {
        // 获取所有锚点元素
        const navContents = document.querySelectorAll(
          ".content-list .content-list-feix"
        )
        console.log(navContents)
        // 所有锚点元素的 offsetTop
        const offsetTopArr = []
        navContents.forEach((item) => {
          offsetTopArr.push(item.offsetTop)
        })
        // 获取当前文档流的 scrollTop
        const scrollTop =
          document.documentElement.scrollTop || document.body.scrollTop
        // 定义当前点亮的导航下标
        console.log(scrollTop)

        let navIndex = 0
        for (let n = 0; n < offsetTopArr.length; n++) {
          // 如果 scrollTop 大于等于第 n 个元素的 offsetTop 则说明 n-1 的内容已经完全不可见
          // 那么此时导航索引就应该是 n 了
          if (scrollTop - 450 >= offsetTopArr[n]) {
            navIndex = n
          }
        }
        // 把下标赋值给 vue 的 data
        this.active = navIndex
      },
    },
    destroy() {
      // 必须移除监听器，不然当该vue组件被销毁了，监听器还在就会出错
      window.removeEventListener("scroll", this.onScroll)
    },
    mounted() {
      window.addEventListener("scroll", this.onScroll)
    },
  }
</script>
<style lang="scss" scoped>
  .face {
    width: 100%;
    height: auto;
    box-sizing: border-box;
    .head-tab {
      position: absolute;
      top: 0;
      z-index: 99;
      width: 100%;
      height: 60px;
    }
    .face-fwli {
      width: 100%;
      height: 550px;
      padding: 0 15%;
      box-sizing: border-box;
      margin-top: 20px;
    }
    .face-fwli img {
      width: 100%;
      height: 100%;
    }
    .content {
      margin: 39px 15% 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      box-sizing: border-box;
      position: relative;
      padding: 3% 3%;
      box-sizing: border-box;
      background: url("../assets/image/index/jybj.png") no-repeat;
      background-size: 100% 100%;
      .txt {
        font-size: 23px;
        font-family: Source Han Sans CN;
        color: #f7f7f7;
        text-align: left;
        width: 100%;
        padding-left: 10px;
      }
      .content-list {
        width: 100%;
      }
      .content-list-feix {
        margin-top: 50px;
      }
      .content-list-yl-title {
        width: 100%;
        text-align: center;
        margin: 30px auto;
        font-size: 20px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #ffffff;
      }
      .content-list-yl-list {
        width: 100%;
        display: flex;
        justify-content: space-between;
      }
      .content-list-yl-item {
        width: 230px;
        height: 350px;
        background: #ffffff;
        border-radius: 50px;
      }
      .content-list-yl-img {
        width: 100%;
        height: 160px;
      }
      .content-list-yl-name {
        width: 100%;
        text-align: center;
        margin-top: 10px;
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: bold;
      }
      .content-list-yl-Price {
        width: 100%;
        text-align: center;
        font-size: 20px;
        font-weight: 600;
        color: #f55e4b;
        margin-top: 8px;
      }
      .content-list-yl-Price span {
        font-size: 14px;
        color: #333333;
      }
      .content-list-yl-text {
        width: 100%;
        padding: 0 6px;
        box-sizing: border-box;
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #666666;
        margin-top: 10px;
      }
      .content-list-yl-btn {
        width: 70px;
        height: 30px;
        background: #ffbb17;
        border-radius: 10px;
        color: #fff;
        margin: 15px auto;
        text-align: center;
        line-height: 30px;
      }
    }
    .showP {
      width: 100%;
      height: 300px;
      margin: 0 auto;
      background: #f7f7f7;
      display: flex;
      flex-direction: column;
      margin-top: 31px;
      align-items: center;
      background: url(../assets/image/index/hzhb.png) no-repeat;
      background-size: 100% 100%;

      padding: 3% 3%;
      box-sizing: border-box;
      .showpTit {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 27px;
        span:first-child {
          font-size: 23px;
          font-family: Source Han Sans CN;
          color: #333333;
        }
        span:nth-child(2) {
          font-size: 16px;
          font-family: Adobe Heiti Std;
          color: #ff5050;
        }
      }
      .content-show {
        width: 75%;
        margin-top: 24px;
        display: flex;
        justify-content: space-between;

        .medium {
          width: 30%;

          img {
            width: 100%;
          }
        }
      }
    }
  }
  .MaoDian {
    width: 50px;
    height: 200px;
    background: #5872ff;
    border: 1px solid #ffffff;
    box-shadow: 0px 9px 12px 1px rgba(55, 51, 73, 0.31);
    position: fixed;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  }
  .MaoDian-item {
    width: 40px;
    height: 30px;
    border-radius: 10px;
    text-align: center;
    font-size: 14px;
    line-height: 30px;
    color: #ffffff;
    display: block;
  }
  .MaoDian-active {
    width: 40px;
    height: 30px;
    background-color: #ffffff;
    border-radius: 10px;
    text-align: center;
    font-size: 14px;
    line-height: 30px;
    display: block;
    color: #3049d0;
  }

  a {
    text-decoration: none;
  }
  .Footer {
    margin-top: 20px;
  }
  .about {
    width: 100%;
    height: 550px;
    padding: 0 15%;
    box-sizing: border-box;
    margin-top: 20px;
    &-center {
      width: 100%;
      height: 500px;
      background: url("../assets/image/index/jybj.png") no-repeat;
      background-size: 100%;
      padding: 5%;
      box-sizing: border-box;
      &-title {
        font-size: 19px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #ffffff;
      }
      p {
        margin-top: 30px;
        font-size: 15px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #ffffff;
      }
    }
  }
</style>
