<template>
  <div class="foot">
    <div class="foot-log">
      <img src="../assets/image/index/bomlog.png" alt="" />
    </div>
    <div class="foot-cente">
      <div>投诉电话：12345</div>
      <div style="ma">© 杭州面具网络科技有限公司版权所有</div>
      <div>联系地址：浙江省杭州市西湖区丰盛九玺天城2号楼4层C5室</div>
    </div>
    <div class="foot-right">
      <div class="foot-top">联系方式</div>
      <div>联系电话：19857016747</div>
      <div>电子邮箱：521462234@qq.com</div>
      icp备案：
      <a href="https://beian.miit.gov.cn" target="_blank">{{ gIcpNo }}</a>
    </div>
  </div>
</template>
<script>
  export default {
    name: "Footer",
    data() {
      return {
        gIcpNo: "",
      }
    },
    mounted() {
      this.gIcpNo = gIcpNo
      console.log(gIcpNo)
      // console.log(window.gIcpNo)
      // this.gIcpNo = window.gIcpNo
    },
  }
</script>
<style lang="scss" scoped>
  .foot {
    width: 100%;
    height: 175px;
    background: #4f53ff;
    color: #fff;
    display: flex;
    align-items: center;
    // justify-content: space-evenly;
    font-size: 12px;
    .foot-log {
      width: 55px;
      height: 49px;
      margin-left: 15%;

      img {
        width: 100%;
        height: 100%;
      }
    }
    .foot-cente {
      padding-left: 20px;
      padding-top: 5px;
      box-sizing: border-box;
      width: 340px;
      border-left: 2px solid #949596;
      margin-left: 2%;
      div {
        margin-top: 5px;
      }
    }
    .foot-right {
      padding-left: 20px;
      padding-top: 5px;
      box-sizing: border-box;
      width: 340px;
      border-left: 2px solid #949596;
      margin-left: 13%;
      div {
        margin-top: 5px;
      }
      .foot-top {
        width: 90px;
        height: 23px;
        background-color: #fff;
        text-align: center;
        line-height: 23px;
        color: #000;
      }
      a {
        color: #fff;
        text-decoration: none;
      }
      a:hover {
        color: #999999;
      }
    }
  }
</style>
