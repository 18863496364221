<template>
  <div class="useragr">
    <Tabbar class="tabbar" />
    <div class="content">
      <h2>用户服务协议</h2>
      <div class="content-text">
        <h4> 1.账号注册 </h4>
        <div>
          1.1 乙方承诺以其真实身份注册成为甲方的用户，并保证所提供的个人身份 资料信息真实、完整、有效，依据法律规定和必备条款约定对所提供的信息承担相应的法律责任。
        </div>
        　 　
        <div>
        1.2 乙方以其真实身份注册成为甲方用户后，需要修改所提供的个人身份资 料信息的，甲方应当及时、有效地为其提供该项服务。
        </div>
        <h4>2.用户账号使用与保管</h4>
        <div>
         2.1 根据必备条款的约定，甲方有权审查乙方注册所提供的身份信息是否真实、有效，并应积极地采取技术与管理等合理措施保障用户账号的安全、有效；乙方有义务妥善保管其账号及密码，并正确、安全地使用其账号及密码。任何一方未尽上述义务导致账号密码遗失、账号被盗等情形而给乙方和他人的民事权利造成损害的，应当承担由此产生的法律责任。
        </div>
        　　
        <div>2.2 乙方对登录后所持账号产生的行为依法享有权利和承担责任。</div>
        <div>
          2.3 乙方发现其账号或密码被他人非法使用或有使用异常的情况的，应及时根据甲方公布的处理方式通知甲方，并有权通知甲方采取措施暂停该账号的登录和使用。
        </div>
        　　 　
        <div>
         2.4 甲方根据乙方的通知采取措施暂停乙方账号的登录和使用的，甲方应当要求乙方提供并核实与其注册身份信息相一致的个人有效身份信息。
        </div>
        　
        <div>
         　2.4.1 甲方核实乙方所提供的个人有效身份信息与所注册的身份信息相一致的，应当及时采取措施暂停乙方账号的登录和使用。
        </div>
        　　
        <div>
         2.4.2 甲方违反 2.4.1 款项的约定，未及时采取措施暂停乙方账号的登录和使用，因此而给乙方造成损失的，应当承担其相应的法律责任 
        </div>
        　
        <div>
         2.4.3 乙方没有提供其个人有效身份证件或者乙方提供的个人有效身份证件与所注册的身份信息不一致的，甲方有权拒绝乙方上述请求。用户同意，与其野猪通行证帐号相关的 一切资料、数据和记录（包括但不限于登录记录、登录后行为、点卡信息等）以野猪公司的系统数据为准。
        </div>
        　
        <div>
         　2.5 乙方为了维护其合法权益，向甲方提供与所注册的身份信息相一致的个人有效身份信息时，甲方应当为乙方提供账号注册人证明、原始注册信息等必要的协助和支持，并根据需要向有关行政机关和司法机关提供相关证据信息资料。
        </div>
        <h4>3.服务的中止与终止</h4>
        <div>
          3.1 乙方有发布违法信息、严重违背社会公德、以及其他违反法律禁止性规定的行为，甲方应当立即终止对乙方提供服务。
        </div>
        <div>
        　3.2 乙方在接受甲方服务时实施不正当行为的，甲方有权终止对乙方提供服务。该不正当行为的具体情形应当在本协议中有明确约定或属于甲方事先明确告知的应被终止服务的禁止性行为，否则，甲方不得终止对乙方提供服务。
        </div>
        <div>
         3.3 乙方提供虚假注册身份信息，或实施违反本协议的行为，甲方有权中止对乙方提供全部或部分服务；甲方采取中止措施应当通知乙方并告知中止期间，中止期间应该是合理的，中止期间届满甲方应当及时恢复对乙方的服务。
        </div>
        <div>
         　3.4 甲方根据本条约定中止或终止对乙方提供部分或全部服务的，甲方应负 举证责任。
        </div>
        <h4>4.用户信息保护</h4>
        <div>
         4.1 甲方要求乙方提供与其个人身份有关的信息资料时，应当事先以明确而易见的方式向乙方公开其隐私权保护政策和个人信息利用政策，并采取必要措施保护乙方的个人信息资料的安全。
        </div>
        <div>
       4.2 未经乙方许可甲方不得向任何第三方提供、公开或共享乙方注册资料中的姓名、个人有效身份证件号码、联系方式、家庭住址等个人身份信息，但下列情况除外：
        </div>
        <div>　4.2.1 乙方或乙方监护人授权甲方披露的；</div>
        <div>　　4.2.2 有关法律要求甲方披露的； 　</div>
        <div>4.2.3 司法机关或行政机关基于法定程序要求甲方提供的； • • 　</div>
        <div>　4.2.4 甲方为了维护自己合法权益而向乙方提起诉讼或者仲裁时；</div>
        <div>　 4.2.5 应乙方监护人的合法要求而提供乙方个人身份信息时。</div>
        　 　 　 　 　
      </div>
      　 　
    </div>
    <Footer />
  </div>
</template>
<script>
import Footer from "@/components/Footer";
import Tabbar from "@/components/Tabbar";
export default {
  components: { Tabbar, Footer },
  name: "Useragr",
};
</script>
<style lang="scss" scoped>
.useragr {
  width: 100%;
  .tabbar {
    background-color: #141415 !important;
    padding: 10px 0;
  }
  .content {
    width: 80%;
    margin: 39px auto 143px;
    h2 {
      font-size: 23px;
      font-family: Source Han Sans CN;
      color: #333333;
      margin-bottom: 41px;
    }
    .content-text {
      box-sizing: border-box;
      padding: 0 20px;
      font-size: 11px;
      font-family: Source Han Sans CN;
      color: #333333;
      line-height: 23px;
      h4{
        text-indent: -2em;
      }
      h4:not(:first-child){
        text-indent: -2em;
        margin: 30px 0;
      }
    }
  }
}
</style>