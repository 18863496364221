<template>
  <div class="out-box">
    <el-carousel trigger="click" height="600px">
      <el-carousel-item v-for="(item, index) in imgArray" :key="index">
        <img :src="item" class="rightImg" />
      </el-carousel-item>
    </el-carousel>
  </div>
</template>
<script>
  export default {
    name: "Shuffl",
    data() {
      return {
        imgArray: [
          require("../assets/image/bg/banner1.png"),
          require("../assets/image/bg/banner2.png"),
          require("../assets/image/bg/banner3.png"),
          require("../assets/image/bg/banner4.png"),
        ],
      }
    },
  }
</script>
<style lang="scss" scoped>
  .out-box {
    height: 600px;
    .el-carousel--horizontal {
      overflow: none;
      height: 600px;
    }
    .el-carousel__indicators--horizontal {
      bottom: 3%;
    }
    .el-carousel__button {
      width: 15px;
      height: 15px;
      border-radius: 50%;
    }
    .rightImg {
      width: 100%;
      height: 600px;
      object-fit: cover;
    }
    ::v-deep .el-carousel__container {
      height: 600px;
    }
  }
</style>
