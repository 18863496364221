<template>
  <div class="home">
    <!-- 轮播图 -->
    <Shuffl />
    <Tabbar class="head-tab" />
    <div class="content">
      <div class="content-title">{{ pageID.itemTit }}</div>
      <div class="content-text">
        <div class="content-a" v-html="pageID.itemGs"></div>
        <div class="content-b">
          <span>{{ pageID.Price }}</span>
          {{ pageID.itemId == 15 ? "" : "万元" }}
        </div>
      </div>
      <div class="content-wenzi" v-html="pageID.text"></div>
      <div class="img-arr">
        <img :src="pageID.itemImg" alt="" />
      </div>
    </div>
    <Footer class="foot-tab"></Footer>
  </div>
</template>

<script>
  import Tabbar from "@/components/Tabbar"
  import Footer from "@/components/Footer"
  import Shuffl from "@/components/Shuffl"
  import { log } from "console"
  export default {
    components: { Tabbar, Footer, Shuffl },
    name: "Item",
    pageID: null,
    data() {
      return {
        pageID: null,
        activeIndex: 0,
        itemArr: [
          {
            itemId: 1,
            itemTit: "母婴儿童",
            Price: "4.7",
            itemGs:
              "<div>由合肥畅聊网络科技有限公司提供，联系电话：15867179796</div>",
            itemImg: require("@/assets/image/index/content/fs1.png"),
          },
          {
            itemId: 2,
            itemTit: "男装会展",
            Price: "4.9",
            itemGs:
              "<div>由杭州无悠网络科技有限公司提供，联系电话：18768189561</div>",
            itemImg: require("@/assets/image/index/content/fs2.png"),
          },
          {
            itemId: 3,
            itemTit: "影视服务",
            Price: "3.1",
            itemGs:
              "<div>由杭州无虑网络科技有限公司提供，联系电话：13607030450</div>",
            itemImg: require("@/assets/image/index/content/fs3.png"),
          },
          {
            itemId: 4,
            itemTit: "能源环保",
            Price: "3.1",
            itemGs:
              "<div>由杭州无虑网络科技有限公司提供，联系电话：13607030450</div>",
            itemImg: require("@/assets/image/index/content/fs4.png"),
          },
          {
            itemId: 5,
            itemTit: "通信设备",
            Price: "4.0",
            itemGs:
              "<div>由合肥畅聊网络科技有限公司提供，联系电话：15867179796</div>",
            itemImg: require("@/assets/image/index/content/fs5.png"),
          },
          {
            itemId: 6,
            itemTit: "旅游出行",
            Price: "2.8",
            itemGs:
              "<div>由合肥畅聊网络科技有限公司提供，联系电话：15867179796</div>",
            itemImg: require("@/assets/image/index/content/fs6.png"),
          },
          {
            itemId: 7,
            itemTit: "生活家具",
            Price: "5.8",
            itemGs:
              "<div>由杭州无悠网络科技有限公司提供，联系电话：18768189561</div>",
            itemImg: require("@/assets/image/index/content/fs7.png"),
          },
          {
            itemId: 8,
            itemTit: "餐饮美食",
            Price: "5.1",
            itemGs:
              "<div>由杭州无虑网络科技有限公司提供，联系电话：13607030450</div>",
            itemImg: require("@/assets/image/index/content/fs8.png"),
          },
          {
            itemId: 9,
            itemTit: "社交服务",
            Price: "8.9",
            itemGs:
              "<div>由杭州无悠网络科技有限公司提供，联系电话：18768189561</div>",
            itemImg: require("@/assets/image/index/content/fs9.png"),
          },
          {
            itemId: 10,
            itemTit: "化妆品牌",
            Price: "7.1",
            itemGs:
              "<div>由杭州无悠网络科技有限公司提供，联系电话：18768189561</div>",
            itemImg: require("@/assets/image/index/content/fs10.png"),
            text: "<div>MENAED是一家专注于为顾客提供高品质化妆品的品牌。该品牌的产品经过精心研发，采用最先进的技术和天然成分，以满足顾客不断变化的需求。本次设计涵盖该品牌的APP及相关衍生产品的设计，延续产品一贯的绿色化妆品理念衍生出新的品牌形象、视觉语言。</div>",
          },
          {
            itemId: 11,
            itemTit: "音乐服务",
            Price: "6.5",
            itemGs:
              "<div>由合肥畅聊网络科技有限公司提供，联系电话：15867179796</div>",
            itemImg: require("@/assets/image/index/content/fs11.png"),
          },
          {
            itemId: 12,
            itemTit: "宠物服务",
            Price: "5.8",
            itemGs:
              "<div>由合肥畅聊网络科技有限公司提供，联系电话：15867179796</div>",
            itemImg: require("@/assets/image/index/content/fs12.png"),
            text: "<div>hi~有它宠物app是自己很用心做的一个项目，希望各位观看愉快，评论区欢迎沟通交流哦～</div>",
          },
        ],
      }
    },
    methods: {},
    created() {
      this.itemArr.forEach((item, index) => {
        if (this.$route.query.id == item.itemId) {
          this.pageID = item
        }
      })
      console.log(this.pageID)
    },
  }
</script>
<style lang="scss" scoped>
  .home {
    width: 100%;
    height: auto;
    box-sizing: border-box;
    .head-tab {
      position: absolute;
      top: 0;
      z-index: 99;
      width: 100%;
      height: 60px;
    }
    .content {
      width: 80%;
      margin: 53px auto 59px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .content-title {
        width: 750px;
        font-size: 23px;
        color: #333333;
      }

      .content-text {
        display: flex;
        width: 750px;
        justify-content: space-between;
        align-items: center;
        .content-a {
          font-size: 14px;
          color: #999999;
          margin-top: 10px;
        }
        .content-b {
          span {
            font-size: 45px;
            color: #17a1ff;
          }
        }
      }
      .content-wenzi {
        width: 750px;
        margin: 20px 0;
        font-weight: 600;
      }
      .img-arr {
        width: 100%;
        // border: 1px solid red;
        display: flex;
        justify-content: center;
        margin-top: 0.8rem;
        img {
          width: 750px;
          border-radius: 20px;
          overflow: hidden;
        }
      }
      .text-arr {
        width: 750px;
        color: #333333;
        font-size: 13px;
        font-weight: 600;
        div {
          margin-top: 12px;
        }
      }
    }
  }
</style>
