<template>
  <div class="consultation">
    <!-- 轮播图 -->
    <Shuffl />
    <Tabbar class="head-tab" />
    <div class="consultation-content">
      <div class="consultation-content-title">用户咨询</div>
      <form class="form">
        <div class="out-form">
          <label for="name">用户名</label>
          <input
            type="text"
            v-model="userName"
            id="name"
            placeholder="请输入您的用户名"
          />
        </div>
        <div class="out-form">
          <label for="pwa">联系方式</label>
          <input
            type="number"
            v-model="password"
            id="pwa"
            placeholder="请输入您的联系方式"
          />
        </div>
        <div class="out-form">
          <label for="pwa">公司规模</label>
          <input
            type="text"
            v-model="guimo"
            id="pwa"
            placeholder="请输入您的公司规模"
          />
        </div>
        <div class="out-form">
          <label for="pwa">咨询业务</label>
          <input
            type="text"
            v-model="yewu"
            id="pwa"
            placeholder="请输入您想咨询的业务"
          />
        </div>
        <div class="out-form-textarea">
          <input
            type="textarea"
            v-model="textarea"
            class="out-form-textarea-input"
            placeholder=""
          />
        </div>
        <button class="btn-submit" @click="ifClikc">提交</button>
      </form>
    </div>
    <Footer class="foot-tab"></Footer>
  </div>
</template>
<script>
  import Tabbar from "@/components/Tabbar"
  import Footer from "@/components/Footer"
  import Shuffl from "@/components/Shuffl"
  export default {
    components: { Tabbar, Footer, Shuffl },
    data() {
      return {
        userName: "",
        password: "",
        guimo: "",
        yewu: "",
        textarea: "",
      }
    },
    methods: {
      ifClikc() {
        if (sessionStorage.getItem("isLogin")) {
          if (
            this.userName == "" ||
            this.guimo == "" ||
            this.guimo == "" ||
            this.yewu == "" ||
            this.textarea == ""
          ) {
            this.$message.error("请填完整信息并进行提交")
          } else {
            this.$message.success("提交成功")
          }
        } else {
          this.$message.error("请登录账号进行提交")
        }
      },
    },
  }
</script>
<style lang="scss" scoped>
  .consultation {
    width: 100%;
    height: auto;
    box-sizing: border-box;

    .head-tab {
      position: absolute;
      top: 0;
      z-index: 99;
      width: 100%;
      height: 60px;
    }
    .consultation-content-title {
      width: 100%;
      text-align: center;
      font-size: 18px;
      font-family: Source Han Sans CN;
      font-weight: 600;
      color: #333333;
      margin-top: 20px;
    }
  }
  .form {
    color: #666666;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 43px;
    .out-form {
      width: 35%;
      border: 1px solid #adadad;
      margin-top: 30px;
      height: 45px;
      display: flex;
      align-items: center;
      border-radius: 5px;
      label {
        text-align: center;
        width: 128px;
        border-right: 1px solid #adadad;
      }
      input {
        flex: 1;
        border: none;
        text-align: center;
        outline: none;
      }
    }
    .out-form-textarea {
      width: 35%;
      border: 1px solid #adadad;
      margin-top: 30px;
      height: 100px;
      border-radius: 5px;
      .out-form-textarea-input {
        height: 100%;
        width: 100%;
        border: none;
        text-align: left;
        outline: none;
      }
    }
    .from-foot {
      display: flex;
      width: 35%;
      margin-top: 47px;
      justify-content: space-between;
      a {
        color: #17a1ff;
      }
      .from-foot-left {
        display: flex;
        .agreess-img {
          cursor: pointer;
        }
        .agree {
          margin: 0 5px;
        }
        #agress {
          display: none;
        }
      }
    }
    .btn-submit {
      margin-top: 34px;
      color: #fff;
      background: #17a1ff;
      height: 54px;
      border: none;
      width: 20%;
      border-radius: 27px;
      font-size: 18px;
      font-family: Source Han Sans CN;
      text-indent: 2px;
      letter-spacing: 2px;
      cursor: pointer;
    }
  }
  .foot-tab {
    margin-top: 50px;
  }
</style>
