<template>
  <div class="publishbtn">
    <div class="publishbtn-concet">
      <div class="publishbtn-concet-title">发布</div>
      <div class="publishbtn-concet-biaoti">
        <div class="publishbtn-concet-name">标题</div>
        <input
          v-model="title"
          class="publishbtn-concet-input"
          placeholder="请输入10字以内标题"
          type="text"
          onkeyup="value=value.replace(/[\d]/g,'') "
          onbeforepaste="clipboardData.setData('text',clipboardData.getData('text').replace(/[\d]/g,''))"
          maxlength="10"
          name="Numbers"
        />
      </div>
      <div class="publishbtn-concet-img">
        <div class="publishbtn-concet-name">图片</div>
        <el-upload
          action="https://mlapp.yqqsc.com/app/add_web_item.do"
          list-type="picture-card"
          :on-preview="handlePictureCardPreview"
          :on-remove="handleRemove"
          :limit="3"
          :file-list="fileList"
          :before-upload="checkFileType"
          :on-success="handelChage"
        >
          <i class="el-icon-plus"></i>
        </el-upload>
        <el-dialog :visible.sync="dialogVisible">
          <img width="100%" :src="imageUrl" alt="" />
        </el-dialog>
      </div>
      <div class="publishbtn-concet-determine" @click="handelClickDetermine">
        点击发布
      </div>
    </div>
  </div>
</template>

<script>
  import { post } from "../utils/axiso"
  export default {
    data() {
      return {
        imageUrl: [],
        dialogVisible: false,
        fileList: [],
        title: "",
      }
    },
    methods: {
      async validImageSize(file, imgWidth, imgHeight) {
        let img = new Image()
        img.src = URL.createObjectURL(file)
        let { w, h } = await new Promise((resolve, reject) => {
          img.onload = () => {
            let { width: w, height: h } = img
            resolve({ w, h })
          }
        })
        if (w * imgHeight !== imgWidth * h) {
          return false
        }
        return true
      },
      handleRemove(file, fileList) {
        console.log(file, fileList)
      },
      handlePictureCardPreview(response, file, fileList) {
        this.imageUrl = file.url
        this.dialogVisible = true
        console.log(response, file, fileList)
      },
      checkFileType(file) {
        const isJPG = file.type === "image/jpeg"
        const isPNG = file.type === "image/png"
        const isPG = isJPG || isPNG //限制图片格式为jpg / png
        const isLt2M = file.size / 1024 / 1024 < 2 //限制图片大小
        const isSize = new Promise(function (resolve, reject) {
          let width = 200
          let height = 100
          let _URL = window.URL || window.webkitURL
          let img = new Image()
          img.onload = function () {
            let valid = img.width >= width && img.height >= height
            valid ? resolve() : reject()
          }
          img.src = _URL.createObjectURL(file)
        }).then(
          () => {
            return file
          },
          () => {
            this.$message.error("上传图片像素要大于600*400!")
            return promise.reject()
          }
        )
        if (!isPG) {
          this.$message.error("上传头像图片只能是 JPG 或 PNG 格式!")
        }
        if (!isLt2M) {
          this.$message.error("上传头像图片大小不能超过 2MB!")
        }
        return isPG && isLt2M && isSize
      },

      handelChage(response, file, fileList) {
        var _this = this
        const reader = new FileReader()
        reader.onload = function (e) {
          _this.imageUrl.push(reader.result)
        }
        reader.readAsDataURL(file.raw)
        console.log(this.imageUrl)
      },
      async handelClickDetermine() {
        if (this.title == "" || this.imageUrl.length < 1) {
          this.$message.error("标题未定义或未上传图片")
        } else {
          const data = {
            imgs: this.imageUrl,
            title: this.title,
          }
          console.log(data.imgs)
          const result = await post("/app/add_web_item.do", data)
          console.log(result)
          if (result.error != "") {
            this.$message.error("上传失败")
          } else {
            this.$emit("handelClickDetermine", true)
          }
        }
      },
    },
    mounted() {
      console.log(this.fileList)
    },
  }
</script>

<style lang="scss">
  .publishbtn {
    width: 100%;
    &-concet {
      padding: 0 328px;
      box-sizing: border-box;
      margin-top: 20px;
      &-title {
        font-size: 47px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #333333;
      }
      &-biaoti {
        display: flex;
        height: 50px;
        line-height: 50px;
        margin-top: 50px;
      }
      &-input {
        flex: 1;
        margin-left: 20px;

        outline: none;
        background: #ffffff;
        border: 2px solid #e6e6e6;
        border-radius: 10px;
        height: 50px;
        padding-left: 20px;
        box-sizing: border-box;
      }
      &-name {
        font-size: 24px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #333333;
      }
      &-determine {
        width: 395px;
        height: 71px;
        background: #17a1ff;
        border-radius: 36px;
        margin: 100px auto;
        font-size: 24px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #ffffff;
        line-height: 71px;
        text-align: center;
      }
    }
  }
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
</style>
