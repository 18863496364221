import axios from "axios"
/*get 请求*/
let baseUrl = "https://mlapp.yqqsc.com";
//let baseUrl = "";
const instance = axios.create({
    //baseURL: "https://mlapp.yqqsc.com",
    timeout: 5000
})
export const get = (url, params = {}) => {
        let fullUrl = baseUrl + url;
        return new Promise((resolve, reject) => {
            instance.get(fullUrl, { params }).then((response) => {
                resolve(response.data) // 这个是精确到data 可以根据需求改动
            }, err => {
                reject(err)
            })
        })
    }
    /** post 请求*/
export const post = (url, data = {}) => {
    let fullUrl = baseUrl + url;
    return new Promise((resolve, reject) => {
        instance.post(fullUrl, data, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            resolve(response.data) // 这个是精确到data 可以根据需求改动
        }, err => {
            reject(err)
        })
    })
}