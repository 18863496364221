<template>
    <div class="Publish">
        <div class="Publish-concet">
            <div class="Publish-concet-title">我的发布</div>
            <div class="Publish-concet-btn" @click="handelClick">
                +发布
            </div>

        </div> 
         
        <div class="Publish-List" v-if="ifs"  v-loading="loading">
            <div class="Publish-List-item"    v-for=" (item,index) in data"  :key="index">
        
                <div class="Publish-List-title">
                    <div class="Publish-List-name">{{item.title}}</div>
                    <div class="Publish-List-icon iconfont" @click="()=>{handelClickRovme(item.id)}">&#xe718;</div>
                </div>
                <div class="Publish-List-time"> 发布时间：{{timestampToTime(item.create_time)}}</div>
                <div class="Publish-List-img"   v-loading="loading2" element-loading-text="审核中...">
                    <img v-for="(item,index) in  item.imgs" :src="item"  :key="index">
                </div>
            </div>
               
        </div>
          <div  class="Publish-tishi" v-else>“您还没有发布任何信息”</div>   
    </div>
  


     
</template>

<script>
import {get} from '../utils/axiso'
export default {
    data() {
        return {
            isshow:false,
            data:'',
            loading: true,
            loading2:true,
            ifs:true
        }
    },
    methods: {
      handelClick(){
        this.$emit('isshow',this.isshow)
      },
        timestampToTime(timestamp) {
    timestamp = timestamp ? timestamp : null;
    let date = new Date(timestamp);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
    let Y = date.getFullYear() + '-';
    let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
    let D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
    let h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
    let m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
    let s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
    return Y + M + D + h + m + s;
  },
      async handelClickRovme(id){
        const result = await get('/app/del_web_item.do',{id:id})
        console.log(result);
        if(result.error == ''){
            this.$message.error("删除成功");
              const result = await get('/app/find_web_item.do')
                console.log(result);
                this.data = result.data
                this.loading = falses
                    if(this.data.length<=0){
                        this.ifs= false
                    }
        }
      }
//       base64ImgtoFile(dataurl, filename = 'file') {
//   let arr = dataurl.split(',')
//   let mime = arr[0].match(/:(.*?);/)[1]
//   let suffix = mime.split('/')[1]
//   let bstr = atob(arr[1])
//   let n = bstr.length
//   let u8arr = new Uint8Array(n)
//   while (n--) {
//     u8arr[n] = bstr.charCodeAt(n)
//   }
//   return new File([u8arr], `${filename}.${suffix}`, {
//     type: mime
//   })
// },
// // base64编码的图片
// var base64Img = 'data:image/png;base64,XXXXX...';
// //转换图片文件
// var imgFile = this.base64ImgtoFile(base64Img);
    },
   async mounted() {
        const result = await get('/app/find_web_item.do')
        console.log(result);
        this.data = result.data
        console.log(this.data.length);
        if(this.data.length<=0){
            this.ifs= false
        }else{
           this.ifs= true  
        }
        this.loading = false
    }
}
</script>

<style lang='scss'>
  .Publish{
    width: 100%;
       &-tishi{
        width: 100%;
        text-align: center;
        line-height: 200px;
        font-size: 30px;
        font-weight: 600;
      }
     &-concet{
      padding: 0  328px;
      box-sizing: border-box;
        margin-top: 20px;
      display: flex;
      &-title{
        height: 25px;
        font-size: 27px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #333333;
        flex: 1;
      }
   
      &-btn{
       width: 138px;
        height: 59px;
        background: #17A1FF;
        border-radius: 17px;
        line-height: 59px;
        text-align: center;
        font-size: 30px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #FFFFFF;
      }
   
    }
    .Publish-List{
        min-height: 500px;
        box-sizing: border-box;
        display: flex;
        max-width: 1200px;
        margin: 0 auto;
        overflow-x: auto;
        &-time{
            text-align: center;
        }
        &-item{
            flex: 1;
            padding: 30px;
            background-color: #eeee;
            margin: 0 20px;
        }
        &-title{
            display: flex;
        }
        &-name{
            flex: 1;
            text-align: center;
        }
        &-icon{
            font-size: 20px;
            font-weight: 600;
        }
        &-img{
            min-height: 340px;
            img{
            display: block;
            width: 50px;
            height: 100px;
            margin: 20px auto;
            }
        }
    }

  }
  .loading-map .el-loading-mask {
  z-index: 0 !important;
  background-color: rgba(0, 0, 0, 0.63);
}
.el-loading-mask .el-loading-spinner .path {
  stroke: rgb(211, 4, 14);
  width: 2rem;
  height: 2rem;
}
.el-loading-mask .el-loading-spinner .el-loading-text {
  font-size: 2rem !important;
  color: red;
}

 
</style>