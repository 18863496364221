import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Face from '../views/Face.vue'
import Regist from "../views/Regist.vue"
import Myself from "../views/Myself.vue"
import Item from "../views/Item.vue"
import Useragr from "../components/Useragr"
import Publish from "../views/publish"
import Consultation from "../views/consultation"
import { log } from 'console'
Vue.use(VueRouter)

const routes = [{
        path: '/Login',
        name: 'Home',
        component: Home,
    },
    {
        path: '/',
        name: 'face',
        component: Face,
    }, {
        path: '/Myself',
        name: 'Myself',
        component: Myself
    },
    {
        path: '/Regist',
        name: 'Regist',
        component: Regist
    }, {

        path: '/Item',
        name: 'Item',
        component: Item
    }, {
        name: Useragr,
        path: '/Useragr',
        component: Useragr
    }, {
        name: "Publish",
        path: '/publish',
        component: Publish,
        beforeEnter(to, from, next) {
            const isLogin = sessionStorage.isLogin
            console.log(isLogin == true);
            if (isLogin) {
                next()
            } else {
                next('/Login')
            }
        }
    }, {
        name: Consultation,
        path: '/consultation',
        component: Consultation
    },
]

const router = new VueRouter({
    routes
})

export default router